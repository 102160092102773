<template>
  <div>
    <b-card title="Main Operations">
      <b-row>
        <b-col
          cols="12"
          lg="6"
          class="my-1 align-items-start"
        >
          <v-select
            v-model="selectedKey"
            :reduce="option => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            placeholder="Select Required Operation"
            :options="statuses"
            @input="handleChange"
          />
        </b-col>
        <b-col
          md="3"
          class="mt-md-1"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :to="{ name: 'Selected-main-operation', params: { key: selectedKey, name: statusName } }"
          >
            <feather-icon
              icon="CornerLeftDownIcon"
              class="mr-50"
            />
            <span class="align-middle">Get Data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions,camelcase
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const orderRepository = RepositoryFactory.get('order')

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      operationSelected: [],
      statuses: [],
      selectedKey: '',
      statusName: '',
    }
  },
  mounted() {
    this.getStatusList()
  },
  methods: {
    async getStatusList() {
      const data = localStorage.getItem('main_operations') ? JSON.parse(localStorage.getItem('main_operations')) : (await orderRepository.getStatusList('ho')).data.data
      if (!localStorage.getItem('main_operations')) localStorage.setItem('main_operations', JSON.stringify(data))
      this.statuses = data.map(n => ({ text: n.name, value: n.key }))
    },
    handleChange() {
      const selectedStatus = this.statuses.find(row => row.value === this.selectedKey)
      const words = selectedStatus.text.toLowerCase().split(' ')
      const titleCaseWords = words.map(word => (word.charAt(0) === '(' ? `(${word.charAt(1).toUpperCase()}${word.slice(2)}` : word.charAt(0).toUpperCase() + word.slice(1)))
      const titleCaseString = titleCaseWords.join(' ')
      this.statusName = decodeURIComponent(titleCaseString).replace(/ /g, '-')
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';
</style>
